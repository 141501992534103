import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.prismicBlogPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  function getSlice(content) {
    if (content.slice_type === "quote") {
      return `<blockquote><p>${content.primary.quote.text} <cite>${content.primary.name_of_the_author.text}</cite></p></blockquote>`
    }

    if (content.slice_type === "text") {
      return `<div>${content.primary.text.html}</div>`
    }

    if (content.slice_type === "script") {
      return `${content.primary.script.text}`
    }
  }

  const slices = post.data.body
    .map(content => {
      return getSlice(content)
    })
    .join(``)

  const blogContent = post.data.post.html + slices

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.data.title.text} description={post.data.blurb.text} />
      <article style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <header>
          <div className="categories" style={{ textAlign: `center` }}>
            {post.data.tags[0].tag.document.data.tag_name}
          </div>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.data.title.text}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
              textAlign: `center`,
            }}
          >
            {post.data.date}
          </p>
        </header>
        <section>
          <div dangerouslySetInnerHTML={{ __html: blogContent }} />
        </section>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/blog/${previous.uid}`} rel="prev">
                {previous.data.title.text}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog/${next.uid}`} rel="next">
                {next.data.title.text}
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicBlogPost(uid: { eq: $slug }) {
      data {
        date(formatString: "MMMM DD, YYYY")
        title {
          text
        }
        blurb {
          text
        }
        post {
          html
        }
        body {
          ... on PrismicBlogPostBodyQuote {
            primary {
              name_of_the_author {
                text
              }
              quote {
                text
              }
            }
            slice_type
          }
          ... on PrismicBlogPostBodyText {
            primary {
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicBlogPostBodyScript {
            primary {
              script {
                text
              }
            }
            slice_type
          }
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
                data {
                  tag_name
                }
              }
            }
            slug
          }
        }
      }
    }
  }
`
